<template>
  <v-app
    :style="{ background: isSPA ? 'rgba(176, 196, 223, 1)' : 'transparent' }"
  >
    <!-- <v-app style="background-color: #fafafa"> -->
    <DevRefreshSessionDialog v-if="isSessionExpired" />
    <SnackBar />
    <router-view
      v-if="(user && user.id) || isPOCRoute"
      ref="appContainer"
      class="cursor-default"
    />
    <DevFeaturesSpeedDialog v-if="isSPA && $route.name !== 'dev-root'" />
  </v-app>
</template>

<script>
import SnackBar from './components/shared/SnackBar'
import { mapState, mapActions } from 'vuex'
import { generateJwt } from './services/auth'
import { sendBusMessage } from './services/message-bus'
import { isSPA } from '@/utils/env'

export default {
  components: {
    SnackBar,
    DevRefreshSessionDialog: () =>
      import('@/components/dev/DevRefreshSessionDialog'),
    DevFeaturesSpeedDialog: () =>
      import('@/components/dev/DevFeaturesSpeedDialog'),
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSPA,
      resizeInterval: null,
      lastHeightSent: null,
    }
  },
  computed: {
    ...mapState('auth', ['user', 'isSessionExpired', 'contextMessage']),
    isPOCRoute() {
      return this.$route.name === 'on-entry-find-duplicates'
    },
  },

  async created() {
    const token = await generateJwt(this.context)

    await this.setJwt(token)

    this.loadContext(this.context)
  },
  mounted() {
    this.resizeInterval = setInterval(this.sendWindowHeight, 1000)

    // Used for standalone version
    window.onbeforeunload = () => clearInterval(this.resizeInterval)
  },
  methods: {
    ...mapActions('auth', ['getMe', 'setJwt', 'setContextMessage']),
    async loadContext(message) {
      await this.setContextMessage(message)

      if (!this.isPOCRoute) {
        await this.getMe()
      }
    },
    sendWindowHeight() {
      const containerElement = this.$refs.appContainer
      if (containerElement) {
        const newHeight = containerElement.$el.offsetHeight

        if (this.contextMessage.packageVersion > 0) {
          if (newHeight !== this.lastHeightSent) {
            sendBusMessage({
              action: 'resizeFrame',
              source: this.$route.name,
              height: `${newHeight}px`,
            })

            this.lastHeightSent = newHeight
          }
        } else if (newHeight !== this.lastHeightSent) {
          sendBusMessage({
            action: 'resizeFrame',
            source: this.$route.name,
            height: newHeight,
          })

          this.lastHeightSent = newHeight
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '../node_modules/typeface-roboto/index.css';
</style>
