import http from '../http'
import { mapToArray, mapToObject } from '../helpers/mappers'
import { jobsStatusColors } from './jobs'

const STATS_RESPONSE_PATHS = {
  attributes: 'attributes', // array of strings
  values: 'values', // array of arrays of strings/numbers
}

const STATS_REQUEST_PATHS = {
  // ISO 8601 encoded date time
  start: 'start',
  end: 'end',

  // An empty list matches all the states
  state: 'states',
  review_state: 'reviewStates',
  auto_merge_state: 'autoMergeStates',
}

export const getDupeSetStats = (payload) => {
  return http
    .$post(
      '/dashboard/dupe_set_stats',
      mapToObject(payload, STATS_REQUEST_PATHS)
    )
    .then((response) => {
      return mapToObject(response, STATS_RESPONSE_PATHS)
    })
}

const RESOLVED_STATS_RESPONSE_PATHS = {
  resolvedBy: 'resolver',
  resolvedTotal: 'resolved',
}

export const getResolvedStats = (agentId, { start, end }) => {
  return http
    .$post(`/agent/${agentId}/resolved`, { start, end })
    .then((response) => {
      return mapToArray(
        response.resolved_dupeSets,
        RESOLVED_STATS_RESPONSE_PATHS
      )
    })
}

const JOBS_STATS_RESPONSE_PATHS = {
  id: 'id',
  label: 'label',
  count: 'count',
}

function jobsStatsExtraMapping(mappedItem) {
  return {
    ...mappedItem,
    color: jobsStatusColors[mappedItem.label],
  }
}

export const getJobsStats = () => {
  return http.$get('/async_tasks/stats').then((response) => {
    const statsWithValues = response.filter((stat) => stat.count > 0)

    return mapToArray(statsWithValues, JOBS_STATS_RESPONSE_PATHS, {
      extraMapping: jobsStatsExtraMapping,
    })
  })
}
