import http from '../http'
import { mapToArray, mapToObject } from '../helpers/mappers'

const USER_RESPONSE_PATHS = {
  id: 'id',
  status: 'status',
  firstName: 'first_name',
  lastName: 'last_name',
  fullName: 'full_name',
  email: 'email',
  isOwner: 'is_owner',
  roleName: 'role.name',
  rolePermissions: 'role.permitted_actions', // list of strings
  managerId: 'manager.id',
  managerFullName: 'manager.full_name',
  subordinates: 'subordinates', // list of strings
  sfProfile: 'sf_profile',
  teamName: 'team.name',
  modules: 'modules',
}

function userResponseExtraMapping(mappedItem) {
  return {
    ...mappedItem,
    subordinatesCount: mappedItem.subordinates.length,
    isBulkGridEnabled: mappedItem.modules?.bulk?.dedupe?.enabled,
  }
}

const USER_REQUEST_PATHS = {
  first_name: 'firstName',
  last_name: 'lastName',
  email: 'email',
  status: 'status', // enabled, disabled
  manager_id: 'managerId',
}

export const getMe = (options) =>
  http.$get('/admin/users/me', options).then((response) => {
    return mapToObject(response, USER_RESPONSE_PATHS, {
      extraMapping: userResponseExtraMapping,
    })
  })

export const getUsers = () =>
  http.$get('/admin/users').then((response) => {
    return mapToArray(response.data, USER_RESPONSE_PATHS, {
      extraMapping: userResponseExtraMapping,
    })
  })

export const updateUser = (userId, payload) => {
  return http.$post(
    `/admin/users/${userId}`,
    mapToObject(payload, USER_REQUEST_PATHS)
  )
}

export const deleteUser = (userId) => http.$delete(`/admin/users/${userId}`)
