import { mapToObject } from '../helpers/mappers'
import http from '../http'
import { formatDate } from '@/utils/dates'

const PAGINATION_RESPONSE_PATHS = {
  perPage: 'pagination.per_page',
  totalItems: 'pagination.total',
  totalPages: 'pagination.pages',
}

export const getMergeErrors = ({ page, searchField, searchString }) => {
  return http
    .$post(
      '/dashboard/writecommand/errors',
      {},
      {
        params: {
          page,
          per_page: 10,
          search_field: searchField,
          search: searchString,
        },
      }
    )
    .then((response) => {
      return {
        errors: response.dupe_sets.map((set) => {
          return {
            id: set.id,
            source: set.final_record.source,
            matchConfidenceScore: set.mcs_category,
            number: set.number,
            mergedAtDateTime: formatDate(set.merged, 'MMMM dd, yyyy K:mm aaa'),
            mergedBy: set.merged_by_name,
            primaryId: set.final_record.Id,
            errors: set.commands.reduce((acc, command) => {
              return acc + command.error_text
            }, ''),
            writeCommandsIds: set.commands.reduce((acc, command) => {
              if (command.error_text) {
                return [...acc, command.id]
              }
              return acc
            }, []),
          }
        }),
        ...mapToObject(response, PAGINATION_RESPONSE_PATHS),
      }
    })
}
