import http from '../http'
import vuetify from '@/plugins/vuetify.js'
import { mapToArray, mapToObject } from '../helpers/mappers'
import { formatDate, formatUtcDateFromNow } from '@/utils/dates'
import { upperFirst } from 'lodash-es'
import { dupeSetsStates } from './constants'
import { orderBy } from 'lodash-es'
import store from '@/store'
import axios from 'axios'

const themeColors = vuetify.framework.theme.themes.light

export const entityColors = {
  Account: themeColors.primary,
  Lead: themeColors.success,
  'Account and Lead': themeColors['ap-light-blue'],
  Contact: themeColors['ap-light-blue'],
  Staging_Lead: '#795548',
  'Lead and Staging_Lead': '#FF9800',
  'Contact and Staging_Lead': '#9C27B0',
}

export const roleColors = {
  Admin: themeColors['ap-dark-blue'],
  'Sales User': themeColors['ap-blue'],
  Account: themeColors['ap-light-blue'],
  Manager: themeColors['ap-green'],
  'End-user': themeColors['ap-red'],
  Bot: themeColors['ap-orange'],
}

const DUPE_SETS_REQUEST_PATHS = {
  // ISO 8601 encoded date time
  start: 'start',
  end: 'end',

  format: 'exportFormat',

  // An empty list matches all the states
  state: 'states',
  review_state: 'reviewStates',
  auto_merge_state: 'autoMergeStates',
}

const DUPE_SETS_REQUEST_QUERY_PARAMS_PATHS = {
  per_page: 'perPage',
  page: 'page',
  order_by_field: 'orderByField',
  order_by: 'orderBy',
  search_field: 'searchField',
  search: 'search',
}

function dupeSetsRequestQueryParamsExtraMapping(mappedItem) {
  return {
    ...mappedItem,

    // get the response paths to send as the field to be ordered by or searched for
    order_by_field: DUPE_SETS_ITEMS_RESPONSE_PATHS[mappedItem.order_by_field],
    search_field: DUPE_SETS_ITEMS_RESPONSE_PATHS[mappedItem.search_field],
  }
}

export const DUPE_SETS_ITEMS_RESPONSE_PATHS = {
  id: 'id',
  number: 'number',
  ownerName: 'owner_name',
  estimatedSecondsToMerge: 'estimated_seconds_to_complete',
  matchConfidenceScore: 'mcs_category',

  createdAt: 'created',
  modifiedAt: 'modified',
  mergedAt: 'merged',
  mergedBy: 'merged_by_name',

  state: 'write_status',

  source: 'final_record.source',
  primaryId: 'final_record.Id',
  name: 'final_record.Name',
  billingStreet: 'final_record.BillingStreet',
  billingState: 'final_record.BillingState',
  billingCity: 'final_record.BillingCity',
  BillingCountry: 'final_record.BillingCountry',
  createdDate: 'final_record.CreatedDate',
}

export const DUPE_SETS_ITEMS_HEROKU_RESPONSE_PATHS = {
  ...DUPE_SETS_ITEMS_RESPONSE_PATHS,
  name: 'final_record.Company',
  billingStreet: 'final_record.Street',
  billingState: 'final_record.State',
  billingCity: 'final_record.City',
  BillingCountry: 'final_record.Country',
}

export function dupeSetsItemsResponseExtraMapping(mappedItem) {
  return {
    ...mappedItem,

    createdAtDate: formatDate(mappedItem.createdAt),
    createdAtDateTime: formatDate(
      mappedItem.createdAt,
      'MMMM dd, yyyy K:mm aaa'
    ),

    modifiedAtDate: formatDate(mappedItem.modifiedAt),
    modifiedAtDateTime: formatDate(
      mappedItem.modifiedAt,
      'MMMM dd, yyyy K:mm aaa'
    ),

    mergedAtDate: formatDate(mappedItem.mergedAt),
    mergedAtDateTime: formatDate(mappedItem.mergedAt, 'MMMM dd, yyyy K:mm aaa'),

    matchConfidenceScoreSymbol: mappedItem.matchConfidenceScore
      ? upperFirst(mappedItem.matchConfidenceScore.charAt(0))
      : undefined,

    isStateMerged: mappedItem.state === dupeSetsStates.FINISHED,
  }
}

export const DUPE_SETS_FIELDS_RESPONSE_PATHS = {
  id: 'id',
  label: 'label',
  name: 'name',
}

export const DUPE_SETS_RESPONSE_PATHS = {
  perPage: 'pagination.per_page',
  totalItems: 'pagination.total',
  isBulkMergeEnabled: 'settings.auto_merge.is_enabled',
}

export function dupeSetsResponseExtraMapping(mappedItem) {
  return {
    ...mappedItem,
    isBulkMergeEnabled: false,
    // Add this back once the problem is solved
    // isBulkMergeEnabled: mappedItem.isBulkMergeEnabled && mappedItem.totalItems > 0,
  }
}

const CancelToken = axios.CancelToken
let cancel

export const getDupeSetsForBulkReview = (payload) => {
  if (cancel) {
    cancel()
  }

  const options = {
    params: mapToObject(payload, DUPE_SETS_REQUEST_QUERY_PARAMS_PATHS, {
      extraMapping: dupeSetsRequestQueryParamsExtraMapping,
    }),
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c
    }),
  }

  return http
    .$post(
      '/dashboard/dupe_set/bulk_merge',
      { start_date: payload.startDate },
      options
    )
    .then((response) => {
      cancel = null

      return {
        stats: [
          {
            label: 'New',
            count: response.stats.new,
            textColor: 'ap-blue--text',
            backgroundColor: 'ap-blue-10',
          },
          {
            label: 'In progress',
            count: response.stats.in_progress,
            textColor: 'ap-yellow--text',
            backgroundColor: 'ap-yellow-10',
          },
          {
            label: 'Resolved',
            count: response.stats.finished,
            textColor: 'ap-green--text',
            backgroundColor: 'ap-green-10',
          },
          {
            label: 'Error',
            count: response.stats.failed,
            textColor: 'ap-red--text',
            backgroundColor: 'ap-red-10',
          },
        ],
        dupeSets: response.dupe_sets.map((el) => mapDupeSet(el)),
        fields: mapToArray(response.fields, DUPE_SETS_FIELDS_RESPONSE_PATHS),
        ...mapToObject(response, DUPE_SETS_RESPONSE_PATHS, {
          extraMapping: dupeSetsResponseExtraMapping,
        }),
      }
    })
}

export const getDupeSets = (payload) => {
  const options = {
    params: mapToObject(payload, DUPE_SETS_REQUEST_QUERY_PARAMS_PATHS, {
      extraMapping: dupeSetsRequestQueryParamsExtraMapping,
    }),
  }

  return http
    .$post(
      '/dashboard/dupe_sets',
      mapToObject(payload, DUPE_SETS_REQUEST_PATHS),
      options
    )
    .then((response) => {
      let dupeSets = null
      const env = localStorage.getItem('env')
      if (env === 'heroku-demo') {
        dupeSets = mapToArray(
          response.dupe_sets,
          DUPE_SETS_ITEMS_HEROKU_RESPONSE_PATHS,
          { extraMapping: dupeSetsItemsResponseExtraMapping }
        )
      } else {
        dupeSets = mapToArray(
          response.dupe_sets,
          DUPE_SETS_ITEMS_RESPONSE_PATHS,
          { extraMapping: dupeSetsItemsResponseExtraMapping }
        )
      }

      return {
        dupeSets,
        fields: mapToArray(response.fields, DUPE_SETS_FIELDS_RESPONSE_PATHS),
        ...mapToObject(response, DUPE_SETS_RESPONSE_PATHS, {
          extraMapping: dupeSetsResponseExtraMapping,
        }),
      }
    })
}

export const exportDupeSets = (payload) => {
  const options = {
    params: mapToObject(payload, DUPE_SETS_REQUEST_QUERY_PARAMS_PATHS, {
      extraMapping: dupeSetsRequestQueryParamsExtraMapping,
    }),
  }

  return http.$post(
    '/dashboard/dupe_sets_async',
    mapToObject(payload, DUPE_SETS_REQUEST_PATHS),
    options
  )
}

// export const getDupeSetByRecord = (agentId, { entityKey, entityName }) => {
//   return http
//     .$post(`/agent/${agentId}/find_dupe_set`, {
//       data: {
//         entity_key: entityKey,
//         entity_name: entityName,
//       },
//     })
//     .then((response) => {
//       if (response && Object.keys(response).length === 0) {
//         return { hasDuplicates: false }
//       }

//       return mapDupeSet(response)
//     })
// }

export const getDupeSetByRecordId = (entityKey) => {
  return http
    .$get(`/agent/dupe_set/by_record/${entityKey}`)
    .then((response) => {
      if (response && Object.keys(response).length === 0) {
        return { hasDuplicates: false }
      }

      return mapDupeSet(response)
    })
}

export const changeDuplicateSet = async (duplicateSetId, payload) => {
  return http
    .$post(`/agent/dupe_set/${duplicateSetId}/change`, payload)
    .then((response) => mapDupeSet(response))
}

export const changeDuplicateSetRecordScope = async (
  duplicateSetId,
  recordId,
  scope
) => {
  return http
    .$post(
      `/agent/dupe_set/${duplicateSetId}/record/${recordId}/do_not_merge`,
      { scope }
    )
    .then((response) => mapDupeSet(response))
}

export const mergeDupicateSet = async (duplicateSetId) => {
  return http.$post(`/agent/dupe_set/${duplicateSetId}/merge`, {
    action: 'merge',
  })
}

export const reprocessDupicateSet = async (writeCommandsIds = []) => {
  await writeCommandsIds.forEach(async (writeCommandId) => {
    await http.$post(`/errormanager/writecommand/error/reprocess`, {
      write_command_id: writeCommandId,
    })
  })
}

export const sendToAdminDupicateSet = async (duplicateSetId, message) => {
  return http.$post(`/agent/dupe_set/${duplicateSetId}/merge`, {
    action: 'notify_other',
    message,
  })
}

export const getDupeSet = async ({ entityId, entityName }) => {
  const response = await http.$post(`/agent/dupe_set/find_or_get`, {
    data: {
      entity_key: entityId,
      entity_name: entityName,
    },
  })

  if (response && Object.keys(response).length === 0) {
    return { hasDuplicates: false }
  }

  return mapDupeSet(response)
}

export const findDupeSet = async (
  agentId,
  { dupeSetId, entityId, entityName }
) => {
  const response = await http.$post(`/agent/${agentId}/find_dupe_set`, {
    id: dupeSetId,
    data: {
      entity_key: entityId,
      entity_name: entityName,
    },
  })

  if (response && Object.keys(response).length === 0) {
    return { hasDuplicates: false }
  }

  return mapDupeSet(response)
}

function mapDupeSet(response) {
  const {
    id,
    number: dupeSetNumber,
    action,
    state,
    owner_name: ownerName,
    created: createdAt,
    merged: mergedAt,
    merged_by_name: mergedBy,
    modified: modifiedAt,
    // rules_meta: { filter: { name: segmentName } = {} } = {},
    assigned_to: { assigned_user: assignedTo } = {},
    final_record: finalRecord,
    final_record_editable_cells: finalRecordEditableCells,
    estimated_seconds_to_complete: estimatedSecondsToComplete,
    mcs_category: matchConfidenceScore,
    rows,
    cells,
    columns,
  } = response || {}

  const segmentName = response?.rules_meta?.filter?.name
  const isFromCache = !response.is_refresh

  // Convert cells array to a Object
  // to access its values easily
  const cellsObj = {}

  rows.forEach((row) => {
    cellsObj[row.id] = {}

    columns.forEach((col) => {
      cellsObj[row.id] = {
        ...cellsObj[row.id],
        [col.id]: {
          isSelected: false,
          isConflicted: false,
        },
      }
    })
  })

  cells.forEach((cell) => {
    cellsObj[cell.row_id][cell.column_id].isSelected = cell.selected
  })

  // Create an Object for the final record
  // with flags to know if it is editable or not
  const finalRecordObj = {}
  Object.keys(finalRecord).forEach((key) => {
    finalRecordObj[key] = {
      isEditable: finalRecordEditableCells.includes(key),
      value: finalRecord[key],
    }
  })

  // Create a column for each entity present in the dupe set
  // API returns all the entities in the same column but in the FE
  // every entity has its own column
  const entities = [...new Set(rows.map((row) => row.group))]
  const primaryColumn = columns.find((column) => column.is_primary)
  const primaryColumns = entities.map((entity, index) => {
    return {
      id: primaryColumn.id,
      name: entity,
      label: 'Primary ' + entity,
      isPrimary: true,
      isSticky: true,
      isLastSticky: index === entities.length - 1,
      selectableAsPrimaryRowsIds: primaryColumn.selectable_primary_ids || [],
    }
  })

  // Ignore the 'Id' and 'source' columns
  // and map each column to our internal structure
  const filteredColumns = columns
    .filter((x) => x.name !== 'Id' && x.name !== 'source')
    .map((column) => {
      let status = undefined
      let tutorialCode = undefined
      let isConflicted = column.conflicted_ids?.length > 0

      if (column.locked) {
        status = 'locked'
        tutorialCode = 'lockedColumn'
      } else if (isConflicted) {
        status = 'conflicted'
        tutorialCode = 'conflictedColumn'
      }

      return {
        id: column.id,
        isPrimary: column.is_primary,
        name: column.name,
        label: column.label,
        isConflicted,
        conflictedRowsIds: column.conflicted_ids || [],
        isRequired: column.required,
        isLocked: column.locked,
        status,
        tutorialCode,
      }
    })

  const gridColumns = [
    ...primaryColumns,
    {
      id: 'mcs',
      isPrimary: true,
      name: 'MCS',
      label: 'MCS',
      isSticky: true,
    },
    ...filteredColumns,
  ]

  // map each row to our internal structure
  const gridRows = rows.map((row) => {
    // Generate Salesforce Url to the record's Details Page
    let link = `${store.state.auth.contextMessage.origin}/lightning/r/${row.data.source}/${row.id}/view`

    // flat row to use in the table
    return {
      id: row.id,
      action: row.action,
      isMergable: row.action === 'merge',
      link,
      isPrimaryRecordSelected:
        cellsObj[row.id]?.[primaryColumn.id]?.isSelected || false,
      ...row.data,
      columns: cellsObj[row.id],
      [row.group]: row.group,
    }
  })

  const isStateMergeError = [
    dupeSetsStates.FAILED,
    dupeSetsStates.HIGH_MCS_FAILED,
  ].includes(state)

  const isStateMerged = [
    dupeSetsStates.FINISHED,
    dupeSetsStates.HIGH_MCS_FINISHED,
  ].includes(state)

  const isStateInQueue = [
    dupeSetsStates.QUEUED,
    dupeSetsStates.HIGH_MCS_QUEUED,
  ].includes(state)

  let stateLabel = 'New'
  let stateBgColor = 'ap-light-blue-10'
  let stateIcon = '$mdi-alert-decagram'
  let stateIconColor = 'ap-blue'
  let stateTextColor = 'ap-blue--text'
  if (isStateInQueue) {
    stateLabel = 'In Progress'
    stateBgColor = 'ap-yellow-10'
    stateIcon = '$mdi-sync'
    stateIconColor = 'ap-yellow'
    stateTextColor = 'ap-yellow--text'
  } else if (isStateMergeError) {
    stateLabel = 'Error'
    stateBgColor = 'ap-red-10'
    stateIcon = '$mdi-alert'
    stateIconColor = 'ap-red'
    stateTextColor = 'ap-red--text'
  } else if (isStateMerged) {
    stateLabel = 'Resolved'
    stateBgColor = 'ap-green-10'
    stateIcon = '$mdi-check-circle'
    stateIconColor = 'ap-green'
    stateTextColor = 'ap-green--text'
  }

  return {
    hasDuplicates: true,
    dupeSetId: id,
    action,
    state,
    stateIcon,
    stateLabel,
    stateBgColor,
    stateIconColor,
    stateTextColor,
    dupeSetNumber,
    ownerName,
    createdAt: formatDate(createdAt),
    mergedAt: formatDate(mergedAt),
    mergedBy,
    modifiedAt: formatDate(modifiedAt),
    segmentName,
    finalRecord: finalRecordObj,
    rows: gridRows,
    columns: gridColumns,
    estimatedSecondsToComplete,
    matchConfidenceScore,
    assignedTo,
    isFromCache,
    isActionMerge: action === 'merge' && !isStateMergeError,
    isActionSendToAdmin: action === 'notify_other' && !isStateMergeError,
    isActionReprocess: action === 'merge' && isStateMergeError,
    isStateNotified: state === dupeSetsStates.NOTIFIED,
    isStateMergeError,
    isStateInQueue,
    isStateMerged,
    isStateNew: !isStateMergeError && !isStateInQueue && !isStateMerged,
    conflictsCount: gridColumns.filter(
      (column) => column.isConflicted && !column.isLocked
    ).length,
    duplicatesCount: gridRows.length,
    entities: gridColumns
      .filter((column) => column.isPrimary && column.id !== 'mcs')
      .map((column) => column.name),
  }
}

const DUPE_SET_HISTORY_RESPONSE_PATHS = {
  id: 'id',
  number: 'number',
  ownerName: 'owner_name',
  createdAt: 'created',
  status: 'write_status.status',
  statusChangedAt: 'write_status.when',
  statusChangedBy: 'write_status.user_name',
  events: 'events',
}

function dupeSetHistoryResponseExtraMapping(mappedItem) {
  const eventsWithoutViewed = mappedItem.events.map((event) => {
    const user = event.role_name || 'System'
    const newValue = event.data.post_value || event.data.error_message

    return {
      columnId: event.data.column_id,
      type: event.type,
      user,
      newValue,
      oldValue: event.data.pre_value,
      rowId: event.data.row_id,
      timeUTC: event.when,
      time: formatUtcDateFromNow(event.when) + ' ago',
    }
  })

  const events = orderBy(eventsWithoutViewed, ['timeUTC'], ['desc'])

  return {
    ...mappedItem,
    createdAtDate: formatDate(mappedItem.createdAt),
    statusChangedAtDate: formatDate(mappedItem.statusChangedAt),
    isStateMerged: mappedItem.status === dupeSetsStates.FINISHED,
    events,
  }
}

export const sendDuplicateSetMessage = async (dupeSetId, message) => {
  return http.$post(`/agent/dupe_set/${dupeSetId}/send_message`, { message })
}

export const getDupeSetHistory = (dupeSetId) => {
  return http.$get(`/agent/dupe_set/${dupeSetId}/history`).then((response) => {
    return mapToObject(response, DUPE_SET_HISTORY_RESPONSE_PATHS, {
      extraMapping: dupeSetHistoryResponseExtraMapping,
    })
  })
}

export const getDupeSetRecordRelatedEntities = (dupeSetId, recordId) => {
  return http
    .$get(`/agent/dupe_set/${dupeSetId}/record/${recordId}/related_entities`)
    .then((response) => {
      return response.map((el) => {
        return {
          entityName: el.entity_name,
          recordsCount: el.records.length,
        }
      })
    })
}

export const updateAutoMergeState = (dupeSetId, newState) => {
  return http.$post(`/agent/dupe_set/${dupeSetId}`, {
    auto_merge_state: newState,
  })
}

export const bulkMergeDuplicateSets = (dupeSetIdsList) => {
  return http.$post('/agent/dupe_set/bulk_merge_async', {
    bulk_type: 'id_list',
    ids: dupeSetIdsList,
  })
}

export const updateAutoMergesStatesBySearchField = (search, searchField) => {
  return http.$post('/agent/dupe_set/bulk_merge_async', {
    bulk_type: 'search',
    search,
    search_field: searchField,
  })
}

// Archive the dupe set. This sets the dupe sets state to archived (no longer actively being managed)
// and also adds an archive event in the dupe set history.
export const archiveDupeSet = (dupeSetId) => {
  return http.$post(`/agent/dupe_set/${dupeSetId}/archive`)
}
