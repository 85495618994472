import axios from 'axios'
import store from '../store'
import { endsWith } from 'lodash-es'
import { isSPA } from '@/utils/env'

const baseURL = process.env.VUE_APP_BASE_URL

export const mockHttp = axios.create({
  baseURL: endsWith(baseURL, '/') ? baseURL.slice(0, -1) : baseURL,
})

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL || '/api/v0',
  timeout: 60000,
  withCredentials: true,
})

const http2 = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
  withCredentials: true,
})

const httpLegacy = axios.create({
  baseURL: process.env.VUE_APP_LEGACY_API_URL,
  timeout: 60000,
  withCredentials: true,
})

// Request helpers ($get, $post, ...) to retrieve data directly
for (const method of [
  'request',
  'delete',
  'get',
  'head',
  'options',
  'post',
  'put',
  'patch',
]) {
  http['$' + method] = function () {
    return this[method].apply(this, arguments).then((res) => res && res.data)
  }
  http2['$' + method] = function () {
    return this[method].apply(this, arguments).then((res) => res && res.data)
  }
  httpLegacy['$' + method] = function () {
    return this[method].apply(this, arguments).then((res) => res && res.data)
  }
  mockHttp['$' + method] = function () {
    return this[method].apply(this, arguments).then((res) => res && res.data)
  }
}

http.interceptors.request.use(
  async (config) => {
    if (process.env.VUE_APP_SPA === 'true') {
      config.baseURL = '/' + localStorage.getItem('env') + config.baseURL
    }

    if (store.state.auth.jwt) {
      config.headers = {
        Authorization: `Bearer ${store.state.auth.jwt}`,
        ...config.headers,
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

function bypassHandler(response) {
  return response
}

async function errorHandler(error) {
  if (error && error.response && error.response.status) {
    const status = error.response.status

    if (status === 403) {
      await store.dispatch('auth/setSessionExpired', true)
    }
  }

  return Promise.reject(error)
}

if (isSPA) {
  http.interceptors.response.use(bypassHandler, errorHandler)
}

export { httpLegacy, http2 }

export default http
